import type { CustomElementOptions } from 'vue';

type CustomElementConfig = {
    component: object, // Fixme: not the best typing here
    options: CustomElementOptions,
}

type CustomElementConfigModule = {
    default: CustomElementConfig
}

type ConfigLoader = () => Promise<CustomElementConfigModule>

export const defineCrhCustomElement = (config: CustomElementConfig) => config;

/**
 * Setup a custom tag, but only if they actually exist on the page.
 *
 * Good for avoiding Vue unless necessary, but not good for
 * if custom-elements are injected into the page later on
 *
 * @param tagName
 * @param configLoader
 */
export const registerTag = async (tagName: string, configLoader: ConfigLoader) => {
    if (!document.getElementsByTagName(tagName).length) {
        return;
    }

    const module = await configLoader();
    const { defineCustomElement } = await import('vue');

    customElements.define(
        tagName,
        defineCustomElement(
            module.default.component,
            module.default.options,
        )
    );
};
