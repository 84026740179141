import { registerTag } from '@/custom_elements_loader.ts';

registerTag(
    'x-national-map',
    () => import('@/components/locator/x_national_map.ts')
);

registerTag(
    'x-state-map',
    () => import('@/components/locator/x_state_map.ts')
);
